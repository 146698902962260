<i18n>
ru:
  incorrect: Неправильная настройка продукта!
  notFound: "Нет активных продуктов в этом разделе"
  notFoundFilter: Не найдены продукты по заданным фильтрам
  noConstructor: Конструктор не найден!
ua:
  incorrect: Неправильне налаштування продукту!
  notFound: "Немає активних продуктів у цьому розділі"
  notFoundFilter: Не знайдено продуктів за вказаними фільтрами
  noConstructor: Конструктор не знайдений!
us:
  incorrect: Incorrect product setting!
  notFound: "No active products in this section"
  notFoundFilter: No products found matching specified filters
  noConstructor: The constructor was not found!
</i18n>

<template>
  <div
    class="v-d-flex v-flex-row v-flex-100 v-menu-list-wrapper"
    data-test-id="menu-list-wrapper"
  >
    <div
      v-if="menuStore.CurrentGroup"
      class="v-d-flex v-flex-row v-flex-100 v-mb-sm"
    >
      <div class="v-menu-title">
        <menu-title
          :title-h1="titleSeo"
          v-if="!stringIsNullOrWhitespace(titleSeo) && isTitleAtFirst"
        />
        <div
          v-else
          class="v-font-weight-600 v-title v-mb-sm"
          v-html="
            sanitize(
              stringIsNullOrWhitespace(
                menuStore.CurrentGroup.SeoData?.PageTitleOverride
              )
                ? menuStore.CurrentGroup.Name
                : menuStore.CurrentGroup.SeoData.PageTitleOverride
            )
          "
        />

        <div
          class="v-mb-sm"
          v-html="menuStore.CurrentGroup.MenuSpecialText"
        />
      </div>
      <menu-filters-page />
    </div>
    <div
      v-if="
        appConfig.VueSettingsPreRun.GiftsRangedShowInMenuAsInCart &&
        appConfig.VueSettingsPreRun.GiftsRangedShowInMenu &&
        appConfig.VueSettingsPreRun.CartLayout === 'Entwash'
      "
      class="v-mb-sm v-flex-100"
    >
      <cart-gifts-ranged />
    </div>
    <menu-list-fanuidhol
      :group-id="groupId"
      :subgroup-id="subgroupId"
      v-if="appConfig.VueSettingsPreRun.MenuListLayout === 'Fanuidhol'"
    />
    <menu-list-orodruin
      v-else-if="appConfig.VueSettingsPreRun.MenuListLayout === 'Orodruin'"
    />
  </div>
  <menu-title
    :title-h1="titleSeo"
    v-if="!stringIsNullOrWhitespace(titleSeo) && !isTitleAtFirst"
  />
</template>

<script setup lang="ts">
import type { MenuList } from '~types/props'

import { useCommon } from '@arora/common'

defineProps<MenuList>()

const route = useRoute()

const titleSeo = ref<string | undefined>(route.meta.TitleH1 as string | undefined)
const isTitleAtFirst = ref<boolean | undefined>(
  route.meta.IsTitleAtFirst as boolean | undefined
)

const { stringIsNullOrWhitespace } = useCommon()
const appConfig = useAppConfig()
const menuStore = useMenuStore()
const { sanitize } = useI18nSanitized()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-menu-title {
  flex: 0 0 50%;
  max-width: 50%;
  color: variables.$Mono1000;

  .v-title {
    font-size: variables.$TextSizeH1;
  }

  @include mixins.sm {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
